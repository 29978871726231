import React from 'react';

import { Box, Button, Spinner, Text } from 'grommet';
import UAPI from '@/UAPI'
//按钮

// <UButton
// 	idx={2} 
// 	icon={[<Clock  color="status-unknown"/>, <Clock color="status-ok"/>]} 
// 	name={["手动", "自动"]}
// 	color={["status-unknown", "status-ok"]}
// 	border 
// 	changeState={this.changeState} />
							
//data数据: 数组
// {
// idx:"按钮uid",
// name:"按钮名称",
// name={["手动", "自动"]}
// checked:是否默认选择,
// changeState=点击事件{this.changeState}
// icon=图标数组{[<Clock  color="status-unknown"/>, <Clock color="status-ok"/>]}, 图标<Clock  color="status-unknown"/>
// color=颜色数组{["status-unknown", "status-ok"]}, 颜色"status-ok"
// border 是否有边框
// }
		
class UButton extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		
		if(this.props.icon===""){
			this.isicon_ok=false
		}else{
			this.isicon_ok=true
			this.isicon_arr=Array.isArray(this.props.icon)
		}
		if(this.props.name===""){
			this.isname_arr=false
		}else{
			this.isname_arr=Array.isArray(this.props.name)
		}
		if(this.props.color===""){
			this.iscolor_arr=false
		}else{
			this.iscolor_arr=Array.isArray(this.props.color)
		}
		let ischecked=typeof this.props.changeState === 'function'
		let icon=''
		if(this.isicon_arr){
			if(ischecked){
				if(this.props.checked*1){
					icon=this.props.icon[1]
				}else{
					icon=this.props.icon[0]
				}
			}else{
				icon=this.props.icon[0]
			}
		}else{
			icon=this.props.icon
		}
		let name=''
		if(this.isname_arr){
			if(ischecked){
				if(this.props.checked*1){
					name=this.props.name[1]
				}else{
					name=this.props.name[0]
				}
			}else{
				name=this.props.name[0]
			}
		}else{
			name=this.props.name
		}
		let color=''
		if(this.iscolor_arr){
			if(ischecked){
				if(this.props.checked*1){
					color=this.props.color[1]
				}else{
					color=this.props.color[0]
				}
			}else{
				color=this.props.color[0]
			}
		}else{
			color=this.props.color
		}
		this.state={
			checked: this.props.checked*1?true:false,
			icon: icon,
			name: name,
			color: color,
			openload:false,
			ischecked:ischecked,
			iswaitting:false,//等待
			ischeck_waitting: false//是否响应等待事件
		};
	}
	componentDidMount=()=>{
		if(this.props.getRef!==undefined){
			this.props.getRef(this.props.idx, this)//用于父组件获取句柄
			this.setState({
				ischeck_waitting:true
			})
		}
	}
	componentWillReceiveProps(nextProps) {
		if(!Array.isArray(nextProps.name) && this.state.name!==nextProps.name){
			this.setState({
				name:nextProps.name
			})
		}
	}
		
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
		name:"",
		checked:false,
		icon: "",
		color: "",
		border: false,
		primary:false,
		size:"small"
	}
	//设置事件
	setEvent = (event={state:false, value:0}) =>{
		let icon=this.state.icon
		if(this.isicon_arr){
			if(event.state){
				icon=this.props.icon[1]
			}else{
				icon=this.props.icon[0]
			}
		}
		let color=this.state.color
		if(this.iscolor_arr){
			if(event.state){
				color=this.props.color[1]
			}else{
				color=this.props.color[0]
			}
		}
		let name=this.state.name
		if(this.isname_arr){
			if(event.state){
				name=this.props.name[1]
			}else{
				name=this.props.name[0]
			}
		}
		
		this.setState({
			checked:event.state,
			icon:icon,
			name:name,
			color:color,
			openload:true,
			iswaitting: false
		})
	}
	changeState=(isevent=false)=>{
		let icon=this.state.icon
		if(this.isicon_arr){
			if(this.state.checked){
				icon=this.props.icon[0]
			}else{
				icon=this.props.icon[1]
			}
		}
		let color=this.state.color
		if(this.iscolor_arr){
			if(this.state.checked){
				color=this.props.color[0]
			}else{
				color=this.props.color[1]
			}
		}
		let name=this.state.name
		if(this.isname_arr){
			if(this.state.checked){
				name=this.props.name[0]
			}else{
				name=this.props.name[1]
			}
		}
		let state=!this.state.checked
		UAPI.onChangeState(this.props, state)
		
		this.setState({
			checked:state,
			icon:icon,
			name:name,
			color:color,
			openload:true,
			iswaitting:true
		})
	}
	waitting_icon=()=>{
		return(
			<div>
				<Spinner
					border={true}
					background="status-warning"
					animation={[
						{ type: 'fadeIn', duration: 1000, size: 'medium' },
						{ type: 'pulse', duration: 800, size: 'medium' },
						]}/>	
			</div>
		)
	}
	render() {
		return(
			<Box>
				{this.isicon_ok ?(
					<Button 
						color={this.state.color} 
						plain={!this.props.border} 
						primary={this.props.primary}
						icon={this.state.ischeck_waitting && this.state.iswaitting?this.waitting_icon():this.state.icon} 
						label={<Text size={this.props.size}>{this.state.name}</Text>}
						onClick={() => this.state.ischecked && (this.state.ischeck_waitting?!this.state.iswaitting:true)&& this.changeState()} />
				):(
					<Button
						size={this.props.size}
						color={this.state.color} 
						plain={!this.props.border} 
						primary={this.props.primary}
						label={<Text size={this.props.size}>{this.state.name}</Text>}
						onClick={() => this.state.ischecked && this.changeState()} />
				) }
			</Box>
		);
	}
}

export default UButton;
