import React from 'react';

import { Box, Text, Button, Layer, DataTable, Meter,Tip} from 'grommet';
import { FormClose } from 'grommet-icons';

import USocket from '@/USocket'

// interface RowType {//const DATA: RowType[] =[{..},{..}]
// 	key: number; //编号
// 	name: string;//名称
// 	location: string;//区域
// 	percent: number;//总占比
// 	paid: number;//总金额
// 	date: string;//日期
// }
const formatter = new Intl.NumberFormat('zh-CN', {//百分比表示
	style: 'unit',
	unit: 'percent',
});
const amountFormatter = new Intl.NumberFormat('zh-CN', {//金额表示
	style: 'currency',
	currency: 'CNY',
	notation: 'compact',
	minimumFractionDigits: 3,
});
const columns= [
	{
		property: 'key',
		header: 'No',
		primary: true,
		footer: 'No',
	},
	{
		property: 'name',
		header: <Text>名称</Text>,
		plain:true,
		footer: '名称',
	},
	{
		property: 'label',
		header: '区域',
		footer: '区域',
		align: 'end',
	},
	{
		property: 'percent',
		header: '总占比',
		footer: '总占比',
		render: (datum) => (
			<Box pad={{ vertical: 'xsmall' }}>
				{datum.percent &&(
					<Tip
						plain
						content={
							<Box pad="small" gap="small" 
								width={{ max: 'small' }}
								round="small"
								background="background-front"
								responsive={false} >
								<Text weight="bold">比例</Text>
								<Text size="small"> {formatter.format(datum.percent)}</Text>
							</Box>
						}
						dropProps={{ align: { left: 'right' } }} >
						<Meter
							values={[{ value: datum.percent, color: `neutral-${(datum.key % 4) + 1}` }]}
							thickness="small"
							type="circle"
							size="xxsmall" />
					</Tip>
				)}
			</Box>
		),
	},
	{
		property: 'paid',
		header: '销售额',
		render: (datum) => amountFormatter.format(datum.paid),
		align: 'end',
		aggregate: 'sum',
		footer: { aggregate: true },
	},
	{
		property: 'date',
		header: '日期',
		footer: '日期',
		render: (datum) => datum.date && new Date(datum.date).toLocaleDateString('en-US'),
		align: 'end',
	},
];

class Aarea extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		
		this.state = {
			show_layer:false,
			layer_data:{},
			data: [
				{
					key:1,
					name: '重庆',
					location: '西南',
					date: '2018-06-10',
					percent: 60,
					paid: 125485565.252,
				}
			],
		}
	}
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
	}
	componentDidMount () {
		USocket.post("carLoca", this.network_post_callback)
	}
	componentWillUnmount=()=>{
		USocket.clean()
	}
	//socket回调
	network_post_callback=(uid, eventid, status, params)=>{
		// console.log(params)  
		if(status===200){
			switch(eventid){
				case "carLoca":
					if(params.errno*1===1033 && params.data!==undefined && typeof params.data==="object"){
						let data=[]
						let idx=1
						params.data.map((item, index)=>{
							data.push({
								key:idx++,
								value:item.key,
								date:item.date,
								label:item.label,
								name:item.name,
								paid:item.paid,
								percent:(item.carase/item.carnum)*100,
								tips:item.tips,
								carase:item.carase,
								carnum:item.carnum,
								wtime:item.wtime
							})
							return index
						})
						this.setState({data: data})
					}
				break;
				default:break;
			}
		}
	}
	
	render() {
		return (
			<Box animation={["fadeIn", "zoomIn"]} >
				<Box align="start" pad="large" fill overflow="auto" flex>
					<DataTable
						sortable replace
						pad={{ horizontal: 'small', vertical: 'xsmall' }}
						columns={columns}
						border={{ body: 'bottom' }}
						step={50}
						data={this.state.data}
						onClickRow={(event) => {
							this.setState({
								show_layer:true, 
								layer_data:event.datum,
							})
						}}/>
						{this.state.show_layer && this.state.layer_data && (
							<Layer
								position="center"
								responsive={false} 
								onEsc={() => this.setState({show_layer:false})}
								onClickOutside={() => this.setState({show_layer:false})} >
								<Box direction="row" align="center" elevation="small" justify="between" border='bottom'>
									<Text margin={{ left: 'small' }}>区域概要</Text>
									<Button icon={<FormClose />} onClick={()=>this.setState({show_layer:false})}/> 
								</Box>
								<Box pad="small" direction="row">
									<Text weight="bolder">{this.state.layer_data.name} . {this.state.layer_data.label}</Text>
								</Box>
								<Box  gap="medium" overflow="auto" flex>
									<Box pad="small">
										<Text weight="bolder">销售金额</Text>
										<Text>{amountFormatter.format(this.state.layer_data.paid)}</Text>
									</Box>
									<Box pad="small" direction="row" gap="medium">
										<Box>
											<Text size='small' weight="bolder">激活比例</Text>
											<Text size='small'>{formatter.format(this.state.layer_data.percent)}</Text>
										</Box>
										<Box>
											<Text size='small' weight="bolder">车辆激活数</Text>
											<Text size='small'>{this.state.layer_data.carase}</Text>
										</Box>
										<Box>
											<Text size='small' weight="bolder">车辆总数</Text>
											<Text size='small'>{this.state.layer_data.carnum}</Text>
										</Box>
									</Box>
									<Box pad="small">
										<Text size='small' weight="bolder">说明</Text>
										<Text size='small'>{this.state.layer_data.tips}</Text>
									</Box>
									<Box pad="small">
										<Text size='small' weight="bolder">统计日期</Text>
										<Text size='small'>{this.state.layer_data.wtime}</Text>
									</Box>
									<Box pad="small">
										<Text size='small' weight="bolder">日期</Text>
										<Text size='small'>{this.state.layer_data.date}</Text>
									</Box>
								</Box>
							</Layer>
						)}
				</Box>
			</Box>
		);
	}
}
export default Aarea;
