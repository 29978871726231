import React from 'react';

import { Box, Text, Form, FormField, TextArea, MaskedInput, TextInput, Pagination, Button, Layer, Notification, DataTable, Spinner, Image, Avatar } from 'grommet';
import { Trash, Edit, StatusGood, FormClose } from 'grommet-icons';

import USocket from '@/USocket'
import Utils from '@/Utils';

// interface RowType {//const DATA: RowType[] =[{..},{..}]
// 	id:number;
// 	key: number; //编号
// 	name: string;//用户名
// 	icon: string;//用像
// 	cartmnum:number;//车辆数
// 	star:number;//星数
// 	dynamic:string;//动态分享
// 	cdata:string;//天气数据 JSON
// 	phone:number;//电话
// 	email:string;//邮箱
// 	address:string;//地址
// 	decla:string;//宣言
// 	count:number;//登录次数
// 	ctime:string;//加入时间
// }

class AUser extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		
		this.state = {
			show_layer:false,
			is_show_del_dialog:false,
			
			is_show_edit:false,
			edit_data:{},//修改的内容ID
			
			layer_data:{},
			data: [
				// {
				// 	id:1,
				// 	key: 1, //编号
				// 	name: 'fspace',//用户名
				// 	icon: 'http://127.0.0.1/assets/image/user/user_friend_5.png',
				// 	star:2,//星数
				// 	cartmnum:1,//车辆数
				// 	dynamic:"今天天气不错",//动态分享
				// 	cdata:"{'id':2,'city':'重庆','air':'44','temp':'27','humidity':'65%','weather':'多云','win':'微风 0级','ate':'2022-05-24 12:37'}",//天气数据 JSON
				// 	phone:13648314883,//电话
				// 	email:"dbin2008@163.com",//邮箱
				// 	address:"重庆市江北区春江名都3号12",//地址
				// 	decla:"我的世界",//宣言
				// 	count:3,//登录次数
				// 	ctime:"2022/05/20 11:47"//加入时间
				// },
			],
			
			
			dialog_type:"unknown",
			error_message:"提示",
			is_show_dialog:false,
			
			contextCount:1,
			cuttent_page:1,
		}
		
		this.context_del_id=0//需要删除的内容ID
		this.xtimer=""
		
		this.columns= [
			{property: 'key',header: 'No',primary: true,footer: 'No',},
			{property: 'username',plain:true,header: '用户名',primary: true,footer: '用户名'},
			{property: 'phone',header: '电话',primary: true,footer: '电话'},
			{property: 'cartmnum',header: '车辆',primary: true,footer: '车辆'},
			{property: 'star',header: '星数',primary: true,footer: '星数'},
			{property: 'count',header: '登录',primary: true,footer: '登录'},
			{property: 'id',header: '操作',footer: '操作',
			render: (datum) => (
				<Box gap="small" pad="small" direction="row">
					<Edit color="status-ok" onClick={(e)=>{
						e.stopPropagation()//停止冒泡事件
						this.user_updata(datum)
					}}/>
					<Trash color="status-error" onClick={(e)=>{
						e.stopPropagation()//停止冒泡事件
						this.context_del_id=datum.id
						this.setState({
							is_show_del_dialog:true,
						})
					}}/>
				</Box>
			) },
		]
	}
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
	}
	componentDidMount () {
		USocket.post("UserCount", this.network_post_callback)
	}
	componentWillUnmount=()=>{
		USocket.clean()
		if(this.xtimer!==""){
			clearTimeout(this.xtimer)
		}
	}
	getPageData=(page)=>{
		USocket.post("getUsers", this.network_post_callback, {
			count:10,//内容条数
			id:0,//内容ID
			isupdata:false,//是否更新
			page:page//页码
		})
	}
	//socket回调
	network_post_callback=(uid, eventid, status, params)=>{
		// console.log(params)  
		if(status===200){
			switch(eventid){
				case "UserCount":
					if(params.errno*1===1033 && params.data!==undefined){
						this.setState({contextCount:params.data.ccount*1})
						this.getPageData(1)
					}
				break;
				case "getUsers":
					if(params.data!==undefined && params.data.length>0){
						
						let userlist=params.data
						let idx=1
						userlist.map((item, index)=>{
							item.key=idx++
							return index
						})
						this.setState({
							data:userlist
						})
					}
				break;
				case "delUser":
					if(params.data!==undefined && params.data===true){
						let contextList=this.state.data
						if(this.context_del_id!==0){
							let idx=contextList.findIndex((item, index)=>{
								if(item.id===this.context_del_id)
									return index
								return -1
							})
							if(idx!==-1){
								contextList.splice(idx, 1)
								this.setState({
									data:contextList
								})
								this.show_dialog('删除成功!', 'normal')
							}
						}
					}else{
						this.show_dialog('删除失败!', 'critical')
					}
					this.context_del_id=0
				break;
				case "updUser":
					if(params.data!==undefined && params.data===true){
						this.setState({is_show_edit: false})
						this.show_dialog("更新成功", "normal")
					}else{
						this.show_dialog("更新用户资料失败", "critical")
					}
				break;
				default:break;
			}
		}
	}
	/**
	 *  type:["normal","warning","info","critical"]
	 */
	show_dialog=(message, type)=>{
		this.setState({
			dialog_type:type?type:"unknown",
			error_message:message,
			is_show_dialog:true
		})
		this.xtimer=setTimeout(() => {
			clearTimeout(this.xtimer)
			this.xtimer=""
			this.setState({
				is_show_dialog:false,
			})
		}, 2000);
	}
	//编辑
	user_updata=(data)=>{//暂时不要编辑
		let edit_data={
			id:data.id,
			username:data.username?data.username:'',
			phone:data.phone?data.phone:'',
			decla:data.decla?data.decla:'',
			dynamic:data.dynamic?data.dynamic:'',
			address:data.address?data.address:'',
			email:data.email?data.email:''
		}
		this.setState({edit_data:edit_data, is_show_edit:true})
	}
	//删除
	context_del=()=>{
		USocket.post("delUser", this.network_post_callback, {
			id:this.context_del_id
		})
		this.setState({is_show_del_dialog:false})
	}
	handleChange = (page) => {
		if(this.state.cuttent_page!==page){
			this.state.cuttent_page=page
			this.getPageData(page)
			this.setState({
				isshow_loading:true
			})
			let timer=setTimeout(()=>{
				clearTimeout(timer)
				this.setState({
					isshow_loading:false
				})
			},100)
		}
	}
	changeState=(event)=>{
		switch (event.idx){
			case "form_data":
				event.value.phone=event.value.phone.replaceAll(" ", '')
				USocket.post("updUser", this.network_post_callback, event.value)
				break;
			default:
				break;
		}
	}
	show_edit(){
		return(
			<Layer
				full="vertical" animation="slide"
				position="center" 
				margin={{ vertical: 'medium', horizontal: 'small' }}
				onEsc={()=>this.setState({is_show_edit:false})}
				onClickOutside={()=>this.setState({is_show_edit:false})}
				responsive={false} >
				<Box width="medium" direction="row" align="center" elevation="small" justify="between" border='bottom' margin={{bottom:"small"}}>
					<Text margin={{ left: 'small' }}>修改用户资料</Text>
					<Button icon={<FormClose />} onClick={()=>this.setState({is_show_edit:false})}/> 
				</Box>
				<Box overflow="auto" flex>
					<Form
						width="100%"
						align="start" 
						justif="start"
						validate="blur"
						value={this.state.edit_data}
						messages={{ invalid: "输入无效", required: "*"}}
						onSubmit={(event) => this.changeState({
							idx: "form_data",
							vid: "",
							state: true,
							long: false,
							value: event.value
						})}
						onChange={(value) => this.setState({edit_data:value})}>
						<FormField label={<Text>用户名</Text>} name="username" pad required
							validate={[
								(value) => {
									if (value && value.length < 3)
										return '用户名不少于3个字';
									if(value==="")
										return '用户名不能为空';
									return undefined;
								},
							]}
							formField={{label:{requiredIndicator:"username"}}}>
							<TextInput name="username" placeholder={"请输入用户名"} />
						</FormField>
						<FormField label={<Text>电话</Text>} name="phone" pad required
							validate={[
								(value) => {
									if (!Utils.isPhone(value))
										return '格式错误';
									return undefined;
								},
							]}
							formField={{label:{requiredIndicator:"phone"}}}>
							<MaskedInput
								mask={[
									{
										length: 3, 
										regexp: /^[1-9]{1,3}$/,
										placeholder: '13x ',
									},
									{ fixed: ' ' },
									{
										length: 4,
										regexp: /^[0-9]{1,4}$/,
										placeholder: ' xxxx',
									},
									{ fixed: ' ' },
									{
										length: 4,
										regexp: /^[0-9]{1,4}$/,
										placeholder: ' xxxx',
									},
								]}
								name="phone"/>
						</FormField>
						<FormField label={<Text>骑行宣言</Text>} name="decla" pad required
							validate={[
								(value) => {
									if (value && value.length < 5)
										return '不少于5个字';
									return undefined;
								},
							]}
							formField={{label:{requiredIndicator:"decla"}}}>
							<TextArea name="decla" placeholder="请输入骑行宣言"/>
						</FormField>
						<FormField label={<Text>动态</Text>} name="dynamic" pad required
							validate={[
								(value) => {
									if (value && value.length < 5)
										return '不少于5个字';
									return undefined;
								},
							]}
							formField={{label:{requiredIndicator:"dynamic"}}}>
							<TextArea name="dynamic" placeholder="请输入个人动态" />
						</FormField>
						<FormField label={<Text>地址</Text>} name="address" pad required
							validate={[
								(value) => {
									if (value && value.length < 10)
										return '不少于10个字';
									return undefined;
								},
							]}
							formField={{label:{requiredIndicator:"address"}}}>
							<TextArea name="address" placeholder="请输入地址" />
						</FormField>
						<FormField label={<Text>邮箱</Text>} name="email" pad required
							validate={[
								(value) => {
									if(!Utils.isEmail(value)){
										return '格式错误';
									}
									return undefined;
								},
							]}
							formField={{label:{requiredIndicator:"email"}}}>
							<TextInput
								name="email"
								placeholder="xxx@xxx.com"/>
						</FormField>
						<Box direction="row" justify="between" margin={{ top: 'medium' }}>
							<Button type="reset" label="重置" />
							<Button type="submit" label="更新资料" primary />
						</Box>
					</Form>
				</Box>
			</Layer>
		)
	}
	show_layer_k(){
		const jsondata=Utils.jsonToObject(this.state.layer_data.cdata)
		return(<Box>
			<Layer
				animation="slide"
				responsive={false} 
				position="center"
				onEsc={() => this.setState({show_layer:false})}
				onClickOutside={() => this.setState({show_layer:false})} >
				<Box direction="row" align="center" elevation="small" justify="between" >
					<Text margin={{ left: 'small' }}>用户资料</Text>
					<Button icon={<FormClose />} onClick={()=>this.setState({show_layer:false})}/> 
				</Box>
				<Box direction="row" gap="medium">
					<Box direction="row" gap="large" 
						border={{color:"brand",side:"bottom"}}>
						<Box height="small"><Avatar size="xlarge" src={this.state.layer_data.icon} /></Box>
						<Box gap="xsmall">
							<Box pad="small">
								<Text size="small" weight="bold">用户名</Text>
								<Text size="small">{this.state.layer_data.username}</Text>
							</Box>
							<Box pad="small">
								<Text size="small" weight="bold">电话</Text>
								<Text size="small">{this.state.layer_data.phone}</Text>
							</Box>
						</Box>
					</Box>
				</Box>
				{this.state.layer_data.qtcode!=="" &&
				<Box pad="small" width="xsmall" height="xsmall">
					<Image src={this.state.layer_data.qtcode} fit="contain"/>
				</Box>}
				<Box overflow="auto" flex gap="medium">
					<Box align="start" pad="small">
						<Text size="small" weight="bold">骑行宣言</Text>
						<Text size="xsmall">
							{this.state.layer_data.decla}</Text>
					</Box>
					<Box pad="small">
						<Text size="small" weight="bold">动态</Text>
						<Text size="xsmall">
							{this.state.layer_data.dynamic}</Text>
					</Box>
					<Box pad="small">
						<Text size="small" weight="bold">地址</Text>
						<Text size="xsmall">{this.state.layer_data.address}</Text>
					</Box>
					<Box pad="small">
						<Text size="small" weight="bold">邮箱</Text>
						<Text size="xsmall">{this.state.layer_data.email}</Text>
					</Box>
					<Box gap="medium" direction="row"  pad="small">
						<Box>
							<Text size="small" weight="bold">车辆</Text>
							<Text size="small">{this.state.layer_data.cartmnum}</Text>
						</Box>
						<Box>
							<Text size="small" weight="bold">星数</Text>
							<Text size="small">{this.state.layer_data.star}</Text>
						</Box>
						<Box>
							<Text size="small" weight="bold">登录次数</Text>
							<Text size="small">{this.state.layer_data.count}</Text>
						</Box>
					</Box>
					{jsondata && <div>
						<Box pad="small" direction="row" gap="medium">
							<Box>
								<Text size="small" weight="bold">城市</Text>
								<Text size="xsmall">{jsondata.city}</Text>
							</Box>
							<Box>
								<Text size="small" weight="bold">天气</Text>
								<Text size="xsmall">{jsondata.weather}</Text>
							</Box>
							<Box>
								<Text size="small" weight="bold">风向</Text>
								<Text size="xsmall">{jsondata.win}</Text>
							</Box>
						</Box>
						<Box pad="small" direction="row" gap="medium">
							<Box>
								<Text size="small" weight="bold">温度</Text>
								<Text size="small">{jsondata.temp}</Text>
							</Box>
							<Box>
								<Text size="small" weight="bold">湿度</Text>
								<Text size="small">{jsondata.humidity}</Text>
							</Box>
							<Box>
								<Text size="small" weight="bold">空气质量</Text>
								<Text size="small">{jsondata.air}</Text>
							</Box>
						</Box>
					</div>}
					<Box pad="small">
						<Text size="small" weight="bold">日期</Text>
						<Text size="small">
							{new Date(this.state.layer_data.ctime).toLocaleDateString('en-US')}
						</Text>
					</Box>
				</Box>
			</Layer>
		</Box>)
	}
	render(){
		return(<Box animation={["fadeIn", "zoomIn"]} >
			<Box align="start" pad="large" fill overflow="auto" flex>
				<DataTable
					sortable replace
					pad={{ horizontal: 'small', vertical: 'xsmall' }}
					border={{ body: 'bottom' }}
					step={50}
					primaryKey="key"
					columns={this.columns}
					data={this.state.data}
					onClickRow={(event) => {
						this.setState({
							show_layer:true, 
							layer_data:event.datum,
						})
					}} />
					<Box align="end" fill margin={{top:'large'}}>
						<Pagination 
							numberItems={this.state.contextCount} 
							page={this.state.cuttent_page}
							step={10}
							onChange={({ page}) => this.handleChange(page)}
							size="medium" />
					</Box>
			</Box>
			{this.state.show_layer && this.state.layer_data && this.show_layer_k()}
			{ this.state.is_show_del_dialog && (
				<Layer
					position="center"
					margin={{ vertical: 'medium', horizontal: 'small' }}
					onEsc={()=>this.setState({is_show_del_dialog:false})}
					onClickOutside={()=>this.setState({is_show_del_dialog:false})}
					responsive={false}
					plain >
					<Box
						align="center"
						direction="row"
						gap="small"
						justify="between"
						round="medium"
						elevation="medium"
						pad={{ vertical: 'xsmall', horizontal: 'small' }}
						background="brand" >
						<Box align="center" direction="row" pad="medium">
							<Box gap="small">
								<Text color="status-error" weight="bold">删除后不能恢复,请谨慎操作!!!</Text>
								<Text>确认需要删除吗???</Text>
							</Box>
						</Box>
						<Button 
							width="small" height="xsmall"
							icon={<StatusGood color="status-error"/>} 
							onClick={()=>this.context_del()} 
							plain />
						</Box>
				</Layer>
			)}
			{this.state.is_show_dialog && (
				<Notification
					status={this.state.dialog_type}
					toast
					title="提示"
					message={this.state.error_message}
					onClose={() => this.setState({is_show_dialog:false})} />
			)}
			{ this.state.is_show_edit && this.show_edit()}
		</Box>)
	}
}
export default AUser;
