//全局API回调规则
//import UAPI from '@/UAPI'

const UAPI = {
	isfunction:(func)=>{//是否为函数
		return typeof func === 'function'
	},
	/**状态改变事件
	 * props	组件的props
	 * state	状态
	 * 
	 * value	值
	 * long		长按
	 */
	onChangeState:(props, state, value="", long=false)=>{
		if(UAPI.isfunction(props.changeState)){
			props.changeState({
				idx: props.idx===undefined?"idx":props.idx,
				vid: props.vid===undefined?"vid":props.vid,
				state: state,
				long: long,
				value: value
			})
		}
	},
	/**值改变事件
	 * props	组件的props
	 * value	值
	 * 
	 * state	状态
	 * long		长按
	 */
	onChangeValue:(props, value, state=true, long=false)=>{
		if(UAPI.isfunction(props.changeState)){
			props.changeState({
				idx: props.idx===undefined?"idx":props.idx,
				vid: props.vid===undefined?"vid":props.vid,
				state: state,
				long: long,
				value: value
			})
		}
	},
	
	
	store:{
		app_name:"奇汇吉®智能",
	},
 };
 export default UAPI;