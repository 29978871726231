// import Utils from '@/Utils'

import { Box, Notification} from 'grommet';
class Utils{
	constructor() {//构造函数
		this.isshowToash=false;
	}
	//判断是否为数字
	static isNumeric(str){
		if((str+"").length===0){
			return false
		}
		return !isNaN(str);
	}
	//判断是否是中文
	static isChinese(str){
		let lst = /[u00-uFF]/;
		return !lst.test(str);
	}
	//是否为数组
	static isArray(arr){
		return Array.isArray(arr)
	}
	//是否为合法字符串
	static isUsername(name, min=5, max=16){
		//帐号是否合法(字母开头，允许5-16字节，允许字母数字下划线组合
		const namePattern = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
		return namePattern.test(name)
	}
	//是否为合法密码
	static isPassword(pass){
		const pwdPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
		return pwdPattern.test(pass)
	}
	//是否为合法身份证
	static isCard(card){
		// 一代身份证号(15位数字)
		// 二代身份证号(18位数字),最后一位是校验位,可能为数字或字符X
		const cardPattern = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;
		return cardPattern.test(card)
	}
	//是否为车牌
	static isCarcard(carcard){
		const carcardPattern = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-HJ-NP-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9 挂学警港澳]{1})$/
		return carcardPattern.test(carcard)
	}
	//是否为电话
	static isPhone(phone){
		phone=phone.replaceAll(" ", '')
		phone=phone.trim()
		const phonePattern = /^1[3-9]\d{9}$/;
		return phonePattern.test(phone)
	}
	//是否为email
	static isEmail(email){
		const emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		return emailPattern.test(email)
	}
	//替换字符串
	static replace(str:string, exp:string, value:string){
		str=str+''
		if(str!==''){
			str =str.split(exp)
			return str.join(value)
		}
	}
	//JSON字符串转对像
	static jsonToObject(json:string){
		if(json){
			json=Utils.replace(json, "'", "\"")
			return JSON.parse(json)
		}
	}
	//中英文混合计算字符长度
	static strlen(str){ 
		if(str===undefined || str===null){
			return 0
		}
		let strlength = 0;
		for (let i=0; i < str.length; ++i){
			if (this.isChinese(str.charAt(i)))
				strlength = strlength + 2;//中文计算为2个字符
			else
				strlength = strlength + 1;//中文计算为1个字符
		}
		return strlength;
	}
	//判断对像是否为空
	static objectIsEmpty(obj){
		for (var name in obj){
			return false; 
		}
		return true; 
	}
	//判断对像是否有key属性
	static objectExistKey(obj, key){
		for (var name in obj){
			if(name===key){
				return true; 
			}
		}
		return false; 
	}
	static getRandom(min=0, max=100){
		let ran=parseInt(Math.random() * (max+10))
		return (ran) % (max - min + 1) + min
	}
	static getRandomWeigh(){
		switch(parseInt(Math.random() * 7)){
			case 1:
				return 'normal'
			case 2:
				return 'bold'
			case 3:
				return 'lighter'
			case 4:
				return 'bolder'
			case 5:
				return 8
			default:
				return 4
		}
	}
	//获取大小
	static getRandomSize(){
		switch(parseInt(Math.random() * 9)){
			case 1:
				return "18px"
			case 2:
				return "small"
			case 3:
				return "medium"
			case 4:
				return "large"
			case 5:
				return "xlarge"
			case 6:
				return "xxlarge"
			case 7:
				return "2xl"
			case 8:
				return "24px"
			default:
				return "12px"
		}
	}
	//获取随机颜色
	static getRandomColor(){
		switch(parseInt(Math.random() * 100)){
			case 1:case 2:case 3:case 4:case 5:
				return 'background-front'
			case 6:case 7:case 8:case 9:case 10:
				return 'control'
			case 11:case 12:case 13:case 14:case 15:
				return 'focus'
			case 16:case 17:case 18:case 19:case 20:
				return 'selected'
			case 21:case 22:case 23:case 24:case 25:
				return 'status-critical'
			case 26:case 27:case 28:case 29:case 30:
				return 'status-warning'
			case 31:case 32:case 33:case 34:case 35:
				return 'status-ok'
			case 36:case 37:case 38:case 39:case 40:
				return 'graph-0'
			case 41:case 42:case 43:case 44:case 45:
				return 'graph-2'
			case 46:case 47:case 48:case 49:case 50:
				return 'graph-4'
			case 51:case 52:case 53:case 54:case 55:
				return 'accent-2'
			case 56:case 57:case 58:case 59:case 60:
				return 'accent-3'
			case 61:case 62:case 63:case 64:case 65:
				return 'accent-4'
			case 66:case 67:case 68:case 69:case 70:
				return 'neutral-1'
			case 71:case 72:case 73:case 74:case 75:
				return 'neutral-4'
			case 76:case 77:case 78:case 79:case 80:
				return 'neutral-2'
			case 81:case 82:case 83:case 84:case 85:
				return 'neutral-3'
			case 86:case 87:case 88:case 89:case 90:
				return 'graph-3'
			case 91:case 92:case 93:case 94:case 95:
				return 'graph-1'
			case 96:case 97:case 98:case 99:case 100:
				return 'status-error'
			default:
				return 'brand';
		}
	}
	
	
	static onClose=()=>{
		this.isshowToash=true;
	}
	static showToast(title, content){//不得行
		return(
			<Box>
				<Notification
					toast
					title={title}
					message={content}
					onClose={this.onClose} 
				/>
			</Box>
		);
	}
	
}
export default Utils;