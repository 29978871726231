import React from 'react';

import {  Box, DataChart, Text, Meter, Stack, Grid, ResponsiveContext} from 'grommet';

import USocket from '@/USocket'

class AUhome extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		
		this.state = {
			user:{
				total:0,
				online:0,
				data:[
					// { date: '2022-01-20', usage: 2, total:20 }, 
				]
			},
			cartm:{
				label_unit:this.props.cartm.label_unit,
				label_total:this.props.cartm.label_total,
				label_use:this.props.cartm.label_use,
				label_avai:this.props.cartm.label_avai,
				label_curr:this.props.cartm.label_curr,
				total:0,//总量
				use: 0,	//激活量
				available:0,//待激活量
				active:0,//鼠标点击激活量
				online:0,//车辆在线
				data:[
					// { date: '2022-01-20', usage: 2, total:20 }, 
				]
			},
			label_number:0,
		}
	}
	static defaultProps = {
		cartm:{
			label_unit:"辆",
			label_total:"总量",
			label_use:"激活量",
			label_avai:"待激活量",
			label_curr:"总量",
		}
	}
	componentDidMount () {
		USocket.post("getUhome", this.network_post_callback, {month:0})
	}
	componentWillUnmount=()=>{
		USocket.clean()
	}
	//socket回调
	network_post_callback=(uid, eventid, status, params)=>{
		// console.log(eventid, params)  
		if(status===200){
			switch(eventid){
				case "getUhome":
					if((params.data!==undefined) && (typeof params.data==='object')){
						let user=undefined
						if(params.data.user!==undefined){
							user={
								total:params.data.user.total*1,
								online:params.data.user.online*1,
								data:params.data.user.data
							}
						}
						let cartm=this.state.cartm
						if(params.data.cartm!==undefined){
							cartm.online=params.data.cartm.online*1
							cartm.total=params.data.cartm.total*1
							cartm.use=params.data.cartm.use*1
							cartm.data=params.data.cartm.data
						}
						if(user!==undefined){
							this.setState({user:user, cartm:cartm})
						}
					}
				break;
				default:break;
			}
		}
	}
	
	show_chartdata(screensize){
		return(<div><Grid columns={{count: screensize==='small'?1:2,size: 'auto',}} gap="medium" fill="vertical">
			{this.state.cartm.data && this.state.cartm.data.length>0 &&(
				<Box align="center" justify="start" gap="large" fill>
					<Box><Text size="xlarge">
						{new Date(this.state.cartm.data[0].date).toLocaleDateString('en-US', {
							year: 'numeric'
						})}年月度车辆增长曲线
					</Text></Box>
					<DataChart
						detail legend
						data={this.state.cartm.data}
						series={[
							{ property: 'usage', label: '在线' },
							{ property: 'total', label: '投入总量'},
							{
								property: 'date',
								label: '月份',
								render: (date) => (<Box align="start" alignContent="start">
									<Text margin={{ horizontal: 'xsmall' }}>
										{new Date(date).toLocaleDateString('en-US', {
											month: 'numeric'
										})}
									</Text>
								</Box>),
							},
						]}
						chart={[
							{
								property: [
									{ property: 'usage', thickness: 'medium' },
									{ property: 'total', thickness: 'medium', opacity: 'medium' },
								],
								type: 'bars',
							},
						]}
						axis={{ x: { property: 'date', granularity: 'fine' }, y: true }}
						guide={{ y:true }}
					    />
				</Box>
			)}
			{this.state.user.data && this.state.user.data.length>0 && (
				<Box align="center" justify="start" gap="large" fill>
					<Box><Text size="xlarge">
						{new Date(this.state.user.data[0].date).toLocaleDateString('en-US', {
							year: 'numeric'
						})}年月度用户增长曲线
					</Text></Box>
					<DataChart
						detail legend
						data={this.state.user.data}
						series={[
							{ property: 'usage', label: '用户在线' },
							{ property: 'total', label: '用户注册' },
							{
								property: 'date',
								label: '月份',
								render: (date) => (<Box align="start" alignContent="start">
									<Text margin={{ horizontal: 'xsmall' }}>
										{new Date(date).toLocaleDateString('en-US', {
											month: 'numeric'
										})}
									</Text>
								</Box>),
							},
						]}
						chart={[
							{
								property: [
									{ property: 'usage', thickness: 'medium' },
									{ property: 'total', thickness: 'medium', opacity: 'medium' },
								],
								type: 'bars',
							},
						]}
						axis={{ x: { property: 'date', granularity: 'fine' }, y: true }}
						guide={{ y:true }}
					    />
				</Box>
			)}
			
		</Grid></div>)
	}
	show_header(screensize){
		const textsize=screensize==='small'?'medium':'xlarge'
		return(<Box gap="xlarge">
			<Grid columns={{count: screensize==='small'?1:3,size: 'auto',}} gap="medium" fill="vertical">
				<Box align="start" gap="medium">
					<Text size={textsize}>在线用户</Text>
					<Text size={textsize}>{this.state.user.online}</Text>
				</Box>
				<Box align="start" gap="medium">
					<Text size={textsize}>注册用户</Text>
					<Text size={textsize}>{this.state.user.total}</Text>
				</Box>
			</Grid>
			<Grid columns={{count: screensize==='small'?1:3,size: 'auto',}} gap="medium" fill="vertical">
				<Box align="start" gap="medium">
					<Text size={textsize}>车辆在线量</Text>
					<Text size={textsize}>{this.state.cartm.online}</Text>
				</Box>
				<Box align="start" gap="medium">
					<Text size={textsize}>车辆激活量</Text>
					<Text size={textsize}>{this.state.cartm.use}</Text>
				</Box>
				<Box align="start" gap="medium">
					<Text size={textsize}>车辆市场总量</Text>
					<Text size={textsize}>{this.state.cartm.total}</Text>
				</Box>
			</Grid>
			
			<Grid columns={{count: screensize==='small'?1:2,size: 'auto',}} gap="medium" fill="vertical">
				<Box align="center" pad="medium">
					<Stack anchor="center">
						<Meter
							type="pie"
							background="light-2"
							size="small"
							values={[
								{ value: this.state.cartm.online,color: "status-ok",
									onHover: (over) => {
										this.setState({
											label_number:over?'在线 '+this.state.cartm.online:""
										})
									}
								}, 
								{ value: this.state.cartm.total-this.state.cartm.online,color: "placeholder",
									onHover: (over) => {
										this.setState({
											label_number:over?'离线 ' +(this.state.cartm.total-this.state.cartm.online):""
										})
									}
								},
							]} />
						<Box background="brand" round="5px" direction="row">{this.state.label_number ?(
							<Text  size="small" weight="bold">{this.state.label_number}</Text>
						):(
							<Text size="small" weight="bold">总量 {this.state.cartm.total}</Text>
						)}
							<Text size="small" weight="bold">{this.state.cartm.label_unit}</Text>
						</Box>
						
					</Stack>
					<Box pad="medium" direction="row" gap="medium">
						<Box>
							<Box width="30px" height="20px" background="status-ok" />
							<Text>在线车辆</Text>
						</Box>
						<Box>
							<Box width="30px" height="20px" background="placeholder" />
							<Text>离线车辆</Text>
						</Box>
					</Box>	
				</Box>
				<Box align="center" pad="medium">
					<Stack anchor="center">
						<Meter
							type="circle"
							background="light-2"
							values={[
								{
									value: this.state.cartm.use,
									onHover: (over) => {
										let cartm=this.state.cartm
										cartm.active=over?cartm.use:0
										cartm.label_curr=over?cartm.label_use:undefined
										this.setState({cartm:cartm})
									},
								},
								{
									value: this.state.cartm.total-this.state.cartm.use,
									color:'neutral-4',
									onHover: (over) => {
										let cartm=this.state.cartm
										cartm.active=over?cartm.total-this.state.cartm.use:0
										cartm.label_curr=over?cartm.label_avai:undefined
										this.setState({cartm:cartm})
									},
								}
							]}
							size="small"
							thickness="medium" />
						<Box align="center">
							<Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
								<Text size="xxlarge" weight="bold">
									{this.state.cartm.active || this.state.cartm.total}
								</Text>
								<Text>{this.state.cartm.label_unit}</Text>
							</Box>
							<Text>{this.state.cartm.label_curr || this.state.cartm.label_total}</Text>
						</Box>
					</Stack>
					<Box pad="medium" direction="row" gap="medium">
						<Box>
							<Box width="30px" height="20px" background="status-ok" />
							<Text>激活车辆</Text>
						</Box>
						<Box>
							<Box width="30px" height="20px" background="neutral-4" />
							<Text>待激活</Text>
						</Box>
					</Box>
				</Box>
			</Grid>
			{this.show_chartdata(screensize)}
		</Box>)
	}
	render() {
		return(<Box pad="medium"  gap="xlarge">
			<ResponsiveContext.Consumer>
			{(screensize)=>this.show_header(screensize)}
			</ResponsiveContext.Consumer>
		</Box>)
		
	}
}
export default AUhome;
