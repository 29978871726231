import axios from "axios"
import UStore from '@/UStore'

const USOCKET_URL_BASE="http://mpetlong.com/TTWService/"
// const USOCKET_URL_BASE="http://192.168.203.98:80/TTWService/"

// USocket.request("POST", 1, 1, this.network_post, "index.php",
// 			{
// 				key:"getversion",
// 				username:"admin",
// 				userpwd:"admin"
// 			},
// 			{'token': 'sdfdsfd4sdfsrefdrhjhdf'}
// 		)

class USocket{
	static pending = new Map()
	static tokenarr=[]
	constructor() {//构造函数
		this.isshowToash=false;
	}
	/**
	 * @param {number | string} eventid 事件ID,必须
	 * @param {function} callback  		回调函数,必须 callback=(uid, eventid, status, data)=>{}
	 * @param {Array} dataArr			参数数组
	 * @param {Object} file				文件
	 */
	static post(eventid, callback, dataArr, file){
		USocket.request("POST", 1, eventid, callback, UStore.userinfo.console,
			{
				key:eventid,
				value:JSON.stringify(dataArr)
			},
			{'token': UStore.userinfo.token},
			file
		)
	}
	/**	socket请求
	 * @param {string} method 			请求类型 "GET", "POST"
	 * @param {number | string} uid 	组件ID,必须
	 * @param {number | string} eventid 事件ID,必须
	 * @param {function} callback 		回调函数,必须 callback=(uid, eventid, status, data)=>{}
	 * @param {Object} uri				调用地址 "index.php"
	 * @param {Object} params			post参数, PHP $_POST['***']
						 * 				{
											key:"getdev",
											username:"admin",
											userpwd:"admin"
										}
	 * @param {Object} headers			post文件头, {'token': '***'}
	 * @param {Object} file				文件
	 */
	static request(method, uid, eventid, callback, uri, params, headers, file){
		let axiosobj=""
		var CancelToken = axios.CancelToken;
		var cancelToken = CancelToken.source();
		switch(method){
			case "GET":
				params['cancelToken']=cancelToken.token
				USocket.addPending(cancelToken)
				axiosobj=axios.get(USOCKET_URL_BASE+uri, params, {headers:headers})
			break;
			case "POST":
				let formData = new FormData();
				Object.keys(params).map((v,i)=>{
					formData.append(v, params[v])
					return i
				})
				if(file!==undefined){
					formData.append('file', file)
				}
				USocket.addPending(cancelToken)
				axiosobj=axios.post(USOCKET_URL_BASE+uri, formData, {headers:headers, cancelToken:cancelToken.token})
			break;
			default:break;
		}
		axiosobj.then(function(respone){
			// if(!Utils.isArray(respone.data)){
			// 	respone.data=undefined
			// }
			if(typeof callback === 'function')
				callback(uid, eventid, respone.status, respone.data)
		})
		axiosobj.catch(function(respone){
			if(typeof callback === 'function')
				callback(uid, eventid, false, respone)
		})
	}
	/**添加请求*/
	static addPending(cancelToken) {
		USocket.tokenarr.push(cancelToken)
		if(!USocket.pending.has(cancelToken))
			USocket.pending.set(USocket.pending.size, cancelToken)
	}
	/**清除所有请求*/
	static clean(){
		// for (const [index, cancelToken] of USocket.pending) {
		// 	cancelToken.cancel(index)
		// }
		USocket.tokenarr.map((token, index)=>{
			token.cancel(index)
			return index
		})
		USocket.pending.clear()
	}
	static allget(uri, params){
		return axios.get(uri, {
			params:{data:params}
		})
	}
	static getall(uri, params){
		axios.all([this.allget(uri[0], params[0]), this.allget(uri[1], params[1])])
		.then(axios.spread(function(acct, perms){
			//两个请求同时都执行完成
			console.log(acct, perms)
		}))
	}
}
export default USocket;