import React from 'react';

import { Box, Text, ResponsiveContext } from 'grommet';
import UStore from '@/UStore'
import UAPI from '@/UAPI'
//选择框
// <UTime 
// 	idx="view_about"
// 	stime="2018.5"
// 	data={[
// 		{label:"杜塞工业设计院控制项目启动", time:"2021-8-01"},
// 		{label:"合美术馆展厅交互艺术设计完成", time:"2021-6-01"},
// 		{label:"智能家居系统设计完成", time:"2020-7-30"},
// 		{label:"筹办交互艺术设计业务", time:"2020-4-05"},
// 		{label:"孵化项目线下店设计完成", time:"2019-12-08"},
// 		{label:"璧山家庭养殖系统验收完成", time:"2019-10-25"},
// 		{label:"智慧农业-浩瀚生态农业系统验收完成", time:"2019-6-13"},
// 		{label:"物联网软硬件系统完成", time:"2018-10-03"},
// 		{label:"物联网系统架构设计", time:"2018-6-01"},
// 		{label:"奇汇吉注册成立", time:"2018-5-15"},
// 	]}
// />

//data数据: 数组
// [
// 	{label:"标签", time:"2022-04-26"},
// ]
class UTime extends React.Component {
	constructor(props) {//构造函数
	    super(props);
	
		this.state={
			checked: this.props.checked,
		};
	}
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
		checked:false,
		data:[//默认关于的数据
			{label:"重庆航天巴山生产组装", time:"2023-5-01"},
			{label:"自研电动助力控制系统V2.0上线", time:"2022-9-01"},
			{label:"自研电动助力控制系统V1.0上线", time:"2022-8-01"},
			{label:"摩宠项目立项", time:"2021-8-01"},
		],
		stime:"2021.8"//开始时间
	}
	onMouseOver=()=>{
		console.log("onMouseOver")
	}
	changeState=(index)=>{
		UAPI.onChangeValue(this.props,index)
	}
	show_one(index, data, screensize){
		return(<Box direction="row" gap="medium" align="center"
			border={
				  {size: "medium",
				  style: "hidden",
				  side: "all"}
				}  >
			<Box width="50px" height="100%" direction="row" align="center">
				<Box width="25px"
					height="100%"
					border="right" />
				<Box
					align="center"
					border
					round="50%"
					width="19px"
					height="19px"
					margin="-10px"
					justify="center">
					<Box 
						border
						round="10%"
						width="8px"
						height="8px" 
						background="status-ok"/>
				</Box>
			</Box>		
			
			<Box 
				align="start"
				gap="xsmall" 
				pad="small" 
				fill
				onClick={() => {this.changeState(index)}}
				hoverIndicator={UStore.store.app_item_select_color}>
				<Text size={screensize==="small"?"10px":"medium"}>{data.label}</Text>
				<Text size={screensize==="small"?"8px":"small"} color="status-disabled">{data.time}</Text>
			</Box>
		</Box>)
	}
	render() {
		return(
			<Box pad="medium">
			<ResponsiveContext.Consumer>
				{(screensize)=>(
					<Box>
						<Box align="center" width="52px"><Text size={screensize==="small"?"10px":"medium"}>至今</Text></Box>
							{this.props.data.map((item,index)=>{
								return (<Box key={index}>{this.show_one(index, item, screensize)}</Box>)
							})}
						<Box align="center" width="52px"><Text size={screensize==="small"?"10px":"medium"}>{this.props.stime}</Text></Box>
					</Box>
				)}
			</ResponsiveContext.Consumer>
			</Box>
		);
	}
}

export default UTime;
