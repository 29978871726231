import React from 'react';

import {  Box, Nav, Text, Button, ResponsiveContext } from 'grommet';
import { Resources, Windows, Edit } from 'grommet-icons';

import UStore from '@/UStore'

import UButton from '@/component/UButton'
import UTime from '@/component/UTime'

import AEdit from './AEdit.js'
import ANewADD from './ANewADD.js'
import AOverview from './AOverview.js'
import AUhome from './AUhome.js'
import AUser from './AUser.js'

import Aarea from './Aarea.js'

const SidebarButton = ({ label, screen, ...rest }) => (
	<Button plain {...rest}>
		{({ hover }) => (
			<Box
				background={hover ? 'teal' : undefined}
				pad={{ horizontal: screen==="small"?"small":'medium', vertical: 'medium' }}
			>
				<Box>{label}</Box>
			</Box>
		)}
	</Button>
);

class Admin extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		this.state = {
			typeList:[],
			typeDisabled:[],
			is_show_dialog:false,
			error_message:"添加失败",
			editid:0,//编辑内容ID
			
			activePage:"数据总览",//激活页面
			
			new_extra:false,//录入控制终端
			extra_list:true//文章列表
		}
		this.pages=['数据总览', '车辆管理', '区域管理', '人员管理', '关于']
	}
	changeState=(event)=>{
		// console.log(event) 
		switch(event.idx){
			case "aedit"://添加成功
				this.setState({extra_list:true})
			break;
			case "new_extra"://录入控制终端
				this.setState({
					extra_list:false,
					new_extra:true,
					editid:0
				})	
			break;
			case "add_extra"://录入车辆信息
				this.setState({
					extra_list:false,
					new_extra:false,
					editid:0
				})
			break;
			case "list_extra"://车辆总览
				this.setState({
					extra_list:true,
					new_extra:false,
				})
			break;
			case "aextra":
				this.setState({
					editid:event.value,
					extra_list:false
				})
			break;
			default:break;
		}
	}
	show_data(){//数据总览
		return(<Box>
			<AUhome />
		</Box>)
	}
	show_car(screensize){//车辆管理
		return(<Box fill>
			<Box fill="horizontal" pad="medium" gap="medium" align="end">
				<Box size="10px" direction="row" gap="large">
					<UButton
						idx="new_extra"
						checked={this.state.iseditstate}
						name="录入控制终端"
						icon={<Edit size={screensize==="small"?"small":"medium"}/>}
						size={screensize==="small"?"small":"medium"}
						changeState={this.changeState}/>
					<UButton
						idx="add_extra"
						checked={this.state.iseditstate}
						name="录入车辆信息"
						icon={<Edit size={screensize==="small"?"small":"medium"}/>}
						size={screensize==="small"?"small":"medium"}
						changeState={this.changeState}/>
					<UButton
						idx="list_extra"
						checked={this.state.iseditstate}
						name="车辆总览"
						icon={<Windows size={screensize==="small"?"12px":"medium"}/>}
						size={screensize==="small"?"small":"medium"}
						changeState={this.changeState}/>
				</Box>
			</Box>
			{this.state.extra_list?(
				<AOverview idx="aextra" changeState={this.changeState}/>
			):(this.state.new_extra?(
				<ANewADD idx="aedit" id={this.state.editid} changeState={this.changeState}/>
			):(
				<AEdit idx="aedit" id={this.state.editid} changeState={this.changeState}/>
			)
			)}
		</Box>)
	}
	show_area(){//区域管理
		return(<Box>
			<Aarea />
		</Box>)
	}
	show_user(){//人员管理
		return(<Box>
			<AUser />
		</Box>)
	}
	show_about(){//关于
		return(<Box>
			<UTime idx="view_about" />
		</Box>)
	}
	switch_page(screensize){
		if(UStore.userinfo.state){
			switch(this.state.activePage){
				case this.pages[0]://数据总览
					return this.show_data(screensize)
				case this.pages[1]://车辆管理
					return this.show_car(screensize)
				case this.pages[2]://区域管理
					return this.show_area(screensize)
				case this.pages[3]://人员管理
					return this.show_user(screensize)
				case this.pages[4]://关于
					return this.show_about()
				default:
					return this.show_data(screensize)
			}
		}else{
			return(<Box fill align="center" justif="center" pad="xlarge" gap="medium"> 
				<Resources size="xlarge" color='status-error'/>
				<Text size={screensize==="small"?"medium":"xlarge"} color='status-error'>404</Text>
				<Text size={screensize==="small"?"medium":"xlarge"} color='status-error'>No page, sorry!</Text>
				<Text size={screensize==="small"?"medium":"xlarge"} color='status-error'>If you believe this page should exist in our library, please file an issue and we will look into it.</Text>
			</Box>)
		}
	}
	render() {
		return (
			<Box 
				animation={["fadeIn", "zoomIn"]} 
				background={UStore.store.app_item_bagd_color}
				border
				round="medium"
				align="center"  justif="center"
				>
				<ResponsiveContext.Consumer>
				{(screensize)=> (
					<Box width="100%" direction="row">
								
						<Box
							width={screensize==="small"?'10%':screensize==="medium"?'10%':'20%'}
							align="start" round="medium" fill="vertical" overflow="hidden">
							<Nav background="brand">
								{this.pages.map((label) => (
									<SidebarButton
										screen={screensize}
										key={label}
										label={<Text color="white" size={screensize==="small"?"xsmall":screensize==="medium"?"small":"medium"}>{label}</Text>}
										active={label === this.state.activePage}
										onClick={() => {
											this.setState({
												activePage:label
											})
										}} />
								))}
							</Nav>
						</Box>
						<Box width={screensize==="small"?'90%':screensize==="medium"?'90%':'80%'} overflow="auto" flex>
							{this.switch_page(screensize)}
						</Box>
					</Box>
				)}
				</ResponsiveContext.Consumer>
			</Box>
		);
	}
}
export default Admin;
