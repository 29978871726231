import React from 'react';

import { withRouter } from 'react-router-dom'

import { Box, Form, FormField, MaskedInput, Text, TextInput, Pagination, Button, Layer, Notification, DataTable, Spinner, Image} from 'grommet';
import { Trash, StatusGood, Cycle, FormClose } from 'grommet-icons';

import USocket from '@/USocket'

const amountFormatter = new Intl.NumberFormat('zh-CN', {//金额表示
	style: 'currency',
	currency: 'CNY',
	notation: 'compact',
	minimumFractionDigits: 4,
});

class AOverview extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		
		this.state = {
			show_layer:false,
			is_show_del_dialog:false,
			is_show_qrcode:false,//二维码
			loading_qrcode_image:"",//二维码图片
			
			layer_data:{},
			
			dialog_type:"unknown",
			error_message:"提示",
			is_show_dialog:false,
			
			contextCount:1,//车辆总量
			cuttent_page:1,//当前页
			contextList:[
				// {
				// 	id:1,
				// 	key: 1, //编号
				// 	name: 'fspace',//用户名
				// 	location: '西南',//区域
				// 	color: 'accent-3',//颜色
				// 	paid: '17352.12',//金额
				// 	version: 10000,//版本
				// 	date: '2018-06-10',//日期
				// 	mac: '94:E6:86:1A:5E:EE',
				// 	carno:'a511-de52-5r52-2622',//车辆编号
				// 	tips:"测试数据",
				// 	keynum:1,//数字钥匙生成次数
				// 	count:2,//配对次数
				// 	fannum:5//分享次数
				// },
			]
		}
		
		this.context_del_id=0//需要删除的内容ID
		this.xtimer=""
		
		this.columns= [
			{property: 'key',header: 'No',primary: true,footer: 'No',},
			{property: 'name',plain:true,header: '用户',primary: true,footer: '用户',},
			{property: 'location',header: '区域',primary: true,footer: '区域',align: 'end',},
			{property: 'carno',header: '车架编号',primary: true,footer: '车架编号',align: 'end',},
			{property: 'tips',header: '车辆说明',primary: true,footer: '车辆说明',align: 'end',},
			{
				property: 'color',header: '颜色',footer: '颜色',
				render: (datum) => (
				<Box width="xsmall" height="xxsmall" background={datum.color}></Box>
			) },
			{property: 'id',header: '操作',footer: '操作',
			render: (datum) => (
				<Box gap="small" pad="small" direction="row">
					<Cycle color="status-ok" onClick={(e)=>{
						e.stopPropagation()//停止冒泡事件
						this.loading_updata(datum.id)
					}}/>
					<Trash color="status-error" onClick={(e)=>{
						e.stopPropagation()//停止冒泡事件
						this.context_del_id=datum.id
						this.setState({
							is_show_del_dialog:true,
						})
					}}/>
				</Box>
			) },
		]
	}
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
	}
	componentDidMount () {
		USocket.post("CarCount", this.network_post_callback)
	}
	componentWillUnmount=()=>{
		USocket.clean()
		if(this.xtimer!==""){
			clearTimeout(this.xtimer)
		}
	}
	getPageData=(page)=>{
		USocket.post("CarConextL", this.network_post_callback, {
			count:10,//内容条数
			id:0,//内容ID
			isupdata:false,//是否更新
			page:page//页码
		})
	}
	//socket回调
	network_post_callback=(uid, eventid, status, params)=>{
		// console.log(eventid, params)  
		if(status===200){
			switch(eventid){ 
				case "CarCount":
					if(params.data!==undefined && params.data.ccount!==undefined){
						this.setState({contextCount:params.data.ccount*1})
						this.getPageData(1)
					}
				break;
				case "findCar":
				if(params.data!==undefined && Array.isArray(params.data)){
					// console.log(params.data)
					let contextList=[]
					let idx=1;
					params.data.map((item, index)=>{
						contextList.push({
							id:item.id,
							key: idx, //编号
							name: item.name,//用户名
							icon:item.icon,
							qrcode:item.qrcode,//二维码
							barcode:item.barcode,//MAC条码
							location: item.location,//区域
							color: item.color,//颜色
							paid: item.paid,//金额
							version: item.version,//版本
							date: item.date,//日期
							mac: item.mac,
							carno:item.carno,//车架编号
							motorno:item.motorno,//电机编号
							tips:item.tips,
							keynum:item.keynum,//数字钥匙生成次数
							count:item.count,//配对次数 
							fannum:item.fannum,//分享次数
							
							position:item.position,//车辆位置
							visible:item.visible,//可见状态
							online:item.online,//在线状态
							caruse:item.caruse,//激活状态
						})
						idx++
						return index
					})
					this.setState({
						is_show_list:true,
						contextList:contextList,
						contextCount:1
					})
				}
				break;
				case "CarConextL":
					if(params.data!==undefined && Array.isArray(params.data)){
						// console.log(params.data)
						let contextList=[]
						let idx=1;
						params.data.map((item, index)=>{
							contextList.push({
								id:item.id,
								key: idx, //编号
								name: item.name,//用户名
								icon:item.icon,
								qrcode:item.qrcode,//二维码
								barcode:item.barcode,//MAC条码
								location: item.location,//区域
								color: item.color,//颜色
								paid: item.paid,//金额
								version: item.version,//版本
								date: item.date,//日期
								mac: item.mac,
								carno:item.carno,//车架编号
								motorno:item.motorno,//电机编号
								tips:item.tips,
								keynum:item.keynum,//数字钥匙生成次数
								count:item.count,//配对次数 
								fannum:item.fannum,//分享次数
								
								position:item.position,//车辆位置
								visible:item.visible,//可见状态
								online:item.online,//在线状态
								caruse:item.caruse,//激活状态
							})
							idx++
							return index
						})
						this.setState({
							is_show_list:true,
							contextList:contextList
						})
					}else{
						this.setState({
							is_show_list:false,
							contextList:[],
							contextCount:1
						})
					}
				break;
				case "delCartm":
					if(params.data!==undefined && params.data===true){
						if(this.context_del_id!==0){
							let contextList=this.state.contextList
							let idx=contextList.findIndex((item, index)=>{
								if(item.id===this.context_del_id)
									return index
								return -1
							})
							if(idx!==-1){
								contextList.splice(idx, 1)
								this.setState({
									contextList:contextList
								})
							}
						}
						this.show_dialog('删除成功!', 'normal')
					}else{
						this.show_dialog('删除失败!', 'critical')
					}
					this.context_del_id=0
				break;
				case "loadQr":
					if(params.data!==undefined && params.data.image!==undefined){
						this.setState({loading_qrcode_image: params.data.image})
					}else{
						this.setState({is_show_qrcode: false})
						this.show_dialog("重构失败", "critical")
					}
					
				break;
				default:break;
			}
		}
	}
	show_dialog=(message, type)=>{
		this.setState({
			dialog_type:type?type:"unknown",
			error_message:message,
			is_show_dialog:true
		})
		this.xtimer=setTimeout(() => {
			clearTimeout(this.xtimer)
			this.xtimer=""
			this.setState({
				is_show_dialog:false,
			})
		}, 2000);
	}
	//重构数字钥匙
	loading_updata=(id)=>{
		USocket.post("loadQr", this.network_post_callback, {
			id:id
		})
		this.setState({is_show_qrcode:true})
	}
	//删除
	context_del=()=>{
		USocket.post("delCartm", this.network_post_callback, {
			id:this.context_del_id
		})
		this.setState({is_show_del_dialog:false})
	}
	handleChange = (page) => {
		if(this.state.cuttent_page!==page){
			this.state.cuttent_page=page
			this.getPageData(page)
			this.setState({
				isshow_loading:true
			})
			let timer=setTimeout(()=>{
				clearTimeout(timer)
				this.setState({
					isshow_loading:false
				})
			},100)
		}
	}
	changeState=(event)=>{
		switch(event.idx){
			case "btn_find":
			if(!(event.value.username==undefined && event.value.location==undefined&&event.value.mac==undefined&&event.value.carno==undefined)){
				let data={
					username:event.value.username,//用户名
					location:event.value.location,//区域
					mac:event.value.mac,//MAC地址
					carno:event.value.carno,//车辆编号
				}
				USocket.post("findCar", this.network_post_callback, data)
			}
			break;
			default:break;
		}
	}
	render(){
		return(<Box animation={["fadeIn", "zoomIn"]}>
			<Box direction='row'>
			<Form
				align="start" 
				justif="start"
				validate="blur"
				messages={{ invalid: "输入无效", required: "输入为空" }}
				onSubmit={(event) => this.changeState({
					idx: "btn_find",
					vid: "",
					state: true,
					long: false,
					value: event.value
				})}>
				<Box direction="row" justify="center" margin={{ top: 'medium'}}>
				<FormField name="location" pad
					validate={[
						(location) => {
							if (location && location.length < 2)
								return '区域不少于2个字';
							if(location==="")
								return '区域不能为空';
							return undefined;
						},
					]}
					formField={{label:{requiredIndicator:"location"}}}>
					<TextInput name="location" placeholder='车辆区域' />
				</FormField>
				<FormField name="username" pad
					validate={[
						(username) => {
							if (username && username.length < 3)
								return '用户名不少于3个字';
							if(username==="")
								return '用户名不能为空';
							return undefined;
						},
					]}
					formField={{label:{requiredIndicator:"username"}}}>
					<TextInput name="username" placeholder='用户名' />
				</FormField>
				</Box>
				<Box direction="row" justify="center" margin={{ top: 'medium'}}>
				<FormField label="控制终端MAC" name="mac" pad
					validate={[
						(mac) => {
							//94:E6:86:1A:5E:EE
							if (mac){
								if(mac.length < 17){
									return '控制终端MAC错误';
								}else if(mac.split(":").length<6){
									return '控制终端MAC错误';
								}
							}
							if(mac==="")
								return '控制终端MAC不能为空';
							return undefined;
						},
					]}
					formField={{label:{requiredIndicator:"mac"}}}>
					<MaskedInput
						mask={[
							{
								length: 2, 
								regexp: /^[0-9,a-f,A-F]{1,2}$/,
								placeholder: 'XX ',
							},
							{ fixed: ':' },
							{
								length: 2,
								regexp: /^[0-9,a-f,A-F]{1,2}$/,
								placeholder: ' XX ',
							},
							{ fixed: ':' },
							{
								length: 2,
								regexp: /^[0-9,a-f,A-F]{1,2}$/,
								placeholder: ' XX ',
							},
							{ fixed: ':' },
							{
								length: 2,
								regexp: /^[0-9,a-f,A-F]{1,2}$/,
								placeholder: ' XX ',
							},
							{ fixed: ':' },
							{
								length: 2,
								regexp: /^[0-9,a-f,A-F]{1,2}$/,
								placeholder: ' XX ',
							},
							{ fixed: ':' },
							{
								length: 2,
								regexp: /^[0-9,a-f,A-F]{1,2}$/,
								placeholder: ' XX ',
							},
						]}
						name="mac"/>
				</FormField>
				<FormField label="车架号" name="carno" pad
					formField={{label:{requiredIndicator:"carno"}}}>
					<MaskedInput
						mask={[
							{ fixed: '0044-' ,placeholder: ' 0044 - '},
							{
								length: 1, 
								regexp: /^[1-9]{1,1}$/,
								placeholder: ' X ',
								options: Array.from({ length: 4 }, (v, k) => k + 1),
							},
							{ fixed: '-' },
							{
								length: 4,
								regexp: /^[0-9]{1,4}$/,
								placeholder: ' XXXX ',
							},
							{ fixed: '-' },
							{
								length: 6,
								regexp: /^[0-9]{1,6}$/,
								placeholder: ' XXXXXX',
							},
						]}
						name="carno"/>
				</FormField>
				</Box>
				<Box direction="row" justify="center" margin={{ top: 'medium'}}>
					<Button type="submit" label="搜索" primary />
				</Box>
				<Box height="xxsmall" direction="row" justify="end">
				</Box>
			</Form>
			</Box>
			<Box align="start" pad="large" fill overflow="auto" flex>
				{this.state.contextList.length>0?(
					<DataTable
						sortable replace
						pad={{ horizontal: 'small', vertical: 'xsmall' }}
						columns={this.columns}
						border={{ body: 'bottom' }}
						step={10}
						data={this.state.contextList}
						onClickRow={(event) => {
							this.setState({
								show_layer:true, 
								layer_data:event.datum,
							})
						}}
						step={20} />
				):(<Box>没有数据</Box>)}
				<Box align="end" fill margin={{top:'large'}}>
					<Pagination 
						numberItems={this.state.contextCount} 
						page={this.state.cuttent_page}
						step={10}
						onChange={({ page}) => this.handleChange(page)}
						size="medium" />
				</Box>
				{this.state.show_layer && this.state.layer_data && (
					<Layer
						position="center"
						responsive={false} 
						onEsc={() => this.setState({show_layer:false})}
						onClickOutside={() => this.setState({show_layer:false})} >
						<Box direction="row" align="center" elevation="small" justify="between" border='bottom'  background={this.state.layer_data.color}>
							<Text margin={{ left: 'small' }}>车辆资料</Text>
							<Button icon={<FormClose />} onClick={()=>this.setState({show_layer:false})}/> 
						</Box>
						<Box gap="medium" pad="medium" background={this.state.layer_data.color}>
							<Box direction="row" gap="medium">
								<Box>
									{this.state.layer_data.qrcode &&<Image
										src={this.state.layer_data.qrcode} 
										/>}
								</Box>
								<Box gap="medium">
									<Box pad="small">
										<Text size="small" weight="bold">用户</Text>
										<Text size="small">{this.state.layer_data.name}</Text>
									</Box>
									<Box pad="small">
										<Text size="small" weight="bold">区域</Text>
										<Text size="small">{this.state.layer_data.location}</Text>
									</Box>
								</Box>
							</Box>
							<Box overflow="auto" flex gap="medium">
								<Box pad="small">
									<Text size="small" weight="bold">车架编号</Text>
									<Text  size="small">{this.state.layer_data.carno}</Text>
								</Box>
								<Box pad="small">
									<Text size="small" weight="bold">电机编号</Text>
									<Text  size="small">{this.state.layer_data.motorno}</Text>
								</Box>
								<Box pad="small">
									<Text size="small" weight="bold">控制器MAC</Text>
									{this.state.layer_data.barcode ?(
										<Image src={this.state.layer_data.barcode} />
									):(
										<Text size="small">{this.state.layer_data.mac}</Text>
									)}
								</Box>
								<Box pad="small" gap="medium" direction="row">
									<Box>
										<Text size="small" weight="bold">金额</Text>
										<Text size="small">{amountFormatter.format(this.state.layer_data.paid)}</Text>
									</Box>
									<Box>
										<Text size="small" weight="bold">版本</Text>
										<Text size="small">{Math.floor(this.state.layer_data.version/10000)}.{Math.floor((this.state.layer_data.version%10000)/100)}.{Math.floor(this.state.layer_data.version%100)}</Text>
									</Box>
									<Box>
										<Text size="small" weight="bold">日期</Text>
										<Text size="small">{new Date(this.state.layer_data.date).toLocaleDateString('en-US')}</Text>
									</Box>
								</Box>
								<Box pad="small">
									<Text size="small" weight="bold">说明</Text>
									<Text size="small">{this.state.layer_data.tips?this.state.layer_data.tips:"没有说明"}</Text>
								</Box>
								<Box pad="small">
									<Text size="small" weight="bold">车辆位置</Text>
									<Text size="small">{this.state.layer_data.position?this.state.layer_data.position:"没有位置信息"}</Text>
								</Box>
								<Box pad="small" gap="medium" direction="row">
									<Box>
										<Text size="small" weight="bold">重置</Text>
										<Text size="small">{this.state.layer_data.keynum}</Text>
									</Box>
									<Box>
										<Text size="small" weight="bold">配对</Text>
										<Text size="small">{this.state.layer_data.count}</Text>
									</Box>
									<Box>
										<Text size="small" weight="bold">分享</Text>
										<Text size="small">{this.state.layer_data.fannum}</Text>
									</Box>
								</Box>
							</Box>
						</Box>
					</Layer>
				)}
			</Box>
			{ this.state.is_show_del_dialog && (
				<Layer
					position="center"
					margin={{ vertical: 'medium', horizontal: 'small' }}
					onEsc={()=>this.setState({is_show_del_dialog:false})}
					onClickOutside={()=>this.setState({is_show_del_dialog:false})}
					responsive={false}
					plain >
					<Box
						align="center"
						direction="row"
						gap="small"
						justify="between"
						round="medium"
						elevation="medium"
						pad={{ vertical: 'xsmall', horizontal: 'small' }}
						background="brand" >
						<Box align="center" direction="row" pad="medium">
							<Box gap="small">
								<Text color="status-error" weight="bold">删除后不能恢复,请谨慎操作!!!</Text>
								<Text>确认需要删除吗???</Text>
							</Box>
						</Box>
						<Button 
							width="small" height="xsmall"
							icon={<StatusGood color="status-error"/>} 
							onClick={()=>this.context_del()} 
							plain />
						</Box>
				</Layer>
			)}
			{ this.state.is_show_qrcode && (
				<Layer
					position="center"
					margin={{ vertical: 'medium', horizontal: 'small' }}
					onEsc={()=>this.setState({is_show_qrcode:false, loading_qrcode_image:''})}
					onClickOutside={()=>this.setState({is_show_qrcode:false, loading_qrcode_image:''})}
					responsive={false} >
					<Box direction="row" align="center" elevation="small" justify="between" border='bottom'>
						<Text margin={{ left: 'small' }}>数字钥匙</Text>
						<Button icon={<FormClose />} onClick={()=>this.setState({is_show_qrcode:false})}/> 
					</Box>
					<Box width="medium" height="medium" align="center" pad="medium" gap="small">
						{this.state.loading_qrcode_image?(
							<Box>
								<Image 
									width="small"
									src={this.state.loading_qrcode_image} 
									fit="cover"/>
							</Box>
						):(
							<Box align="center" fill margin="medium">
								<Spinner  border={[{side: 'all',color: 'brand',size: 'large',style: 'dotted'}]} />
								<Text>重构中...</Text>
							</Box>
						)}
						
					</Box>
				</Layer>
			)}
			{this.state.is_show_dialog && (
				<Notification
					status={this.state.dialog_type}
					toast
					title="提示"
					message={this.state.error_message}
					onClose={() => this.setState({is_show_dialog:false})} />
			)}
		</Box>)
	}
}
export default withRouter(AOverview);
