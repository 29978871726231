import React from 'react';

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import {  Grommet, Main } from 'grommet';
import './App.css';

import UStore from '@/UStore'

import AdminIndex from './view/admin/Admin'

import Login from '@/component/Login'

import { grommet } from 'grommet/themes';

class App extends React.Component {
	constructor(props) {//构造函数
		super(props);
		UStore.file.readUser()
		this.state={
			islogin:false
		}
	}
	changeState=(event)=>{
		switch(event.idx){
			case "Login":
				if(event.state===true){
					this.setState({
						islogin:true
					})
				}
			break;
			default:break;
		}
	}
	router(){
		return(
			<HashRouter>
				<Switch>
					<Route path="/admin" component={AdminIndex} />
					
					<Redirect from="/" to="/admin" exact />
				</Switch>
			</HashRouter>
		)
	}
	render() {
		return (
			<div className="App" key={this.state.islogin}>
				<Grommet theme={grommet} style={{ flex: '0 0 auto', height:'100%' }}>
					<HashRouter>
					<Login idx="Login" changeState={this.changeState}/>
					<Main background={UStore.store.app_themed} elevation="large" pad="large" gap="large" border>
						<this.router />
					</Main>
					</HashRouter>
				</Grommet>
			</div>
		);
	}
}
export default App;
