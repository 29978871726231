//全局变量
//import UStore from '@/UStore'

import store from 'store'
const USER_KEY = 'user_key';

const UStore = {
    pageTitle:"首页",
    userinfo:{
		username:"",
		token:"",
		home:"index.php",//用户主页
		console:"index.php",//用户控制页
		state:false//是否可编辑状态
	},
	file:{
		saveUser:(data)=>{//保存用户
			if((data.username!==undefined) && (data.token!==undefined)){
				store.set(USER_KEY,data);
				UStore.userinfo.username=data.username
				UStore.userinfo.token=data.token
				UStore.userinfo.state=data.state
				// console.log(data)
				return true
			}
			return false
		},
		readUser:()=>{//读取用户
			let data=store.get(USER_KEY) || {}
			if(data!==undefined){
				if(data.username!==undefined)
					UStore.userinfo.username=data.username
				if(data.token!==undefined)
					UStore.userinfo.token=data.token
				if(data.state!==undefined)
					UStore.userinfo.state=data.state
			}
			return true
		},
		removeUser:()=>{//删除用户
			store.remove(USER_KEY);
		}
	},
	
	store:{
		app_name:"奇汇吉®智能",
		app_tips:"奇迹 | 汇集 | 吉祥",
		app_href:"http://fspace.cn",
		
		app_themed:"dark-1",//主题
		app_item_background:"dark-3",//背景颜色
		app_item_color:"light-5",//元素颜色,图标等元素
		app_item_select_color:"brand",//选择元素颜色,图标等元素
		app_item_bagd_color:"dark-1",//元素背景颜色,图标等元素
		app_item_context:"light-3",//内容颜色
		app_item_title:"light-2",//标题颜色
		app_item_disabled:"dark-2",//不可选颜色
		app_item_online:"status-disabled",//元素分隔线
	},
	
	history:{
		super: "",
		next: "",
		url: "fspace.cn"
	},
 };
 export default UStore;