import React from 'react';

import { Box, TextInput, Text, ResponsiveContext } from 'grommet';

import Utils from '@/Utils'
import UAPI from '@/UAPI'

//输入框
// <UInput 
// 	idx={6} 
// 	icon={[Light_off, Light_on]}
// 	changeState={this.changeState}
//  getRef={this.getRef}/>
							
class UInput extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		
		let isonclick=this.props.isonclick
		if(this.props.isinput){
			isonclick=false
		}
		this.state={
			isinput:this.props.isinput,//是否输入
			isonclick:isonclick,
			value:this.props.value,
			count:Utils.strlen(this.props.value)
		};
	}
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
		isonclick:true,//是否响应点击事件
		isinput:false,//是否输入
		align:"center",//对齐
		label:"",//标签
		tips:"",//请输入***
		value:"",
		changeState:"",//事件函数
		maxlength: 12
	}
	//失去焦点
	onBlur=()=>{
		if(this.state.isonclick){
			this.setState({
				isinput:false,
			})
		}
		if(this.state.value==="")
			this.setState({value:this.props.tips})
		UAPI.onChangeValue(this.props, this.state.value)
	}
	onChange=(event)=>{
		let value=event.target.value
		let count=Utils.strlen(value)
		if(count<=this.props.maxlength){
			this.setState({
				value:value,
				count:count
			})
		}
	}
	onClick=()=>{
		if(this.state.isonclick){
			this.setState({
				isinput:true,
			})
		}
	}
	show_input=(screensize)=>{
		return(
			<Box 
				align={screensize==="small"?"start":"center"} 
				justify={this.props.align} 
				gap={screensize==="small"?"large":"small"}
				direction={screensize==="small"?"column":"row"}
				fill>
				{
					this.props.label!=="" && (
						<Text size="medium" weight="bolder">{this.props.label}: </Text>
					)
				}
				{ this.state.isinput ?(
					<Box direction="row" align="center">
						<TextInput
							reverse 
							icon={<Text size="small" >{this.state.count}/{this.props.maxlength}</Text>}
							value={this.state.value}
							size="medium" textAlign="center" placeholder={this.props.tips} 
							onChange={(event)=>{this.onChange(event)}} 
							onBlur={()=>{this.onBlur()}} />
					</Box>
				):(
					<Text size="medium" weight="bolder" onClick={()=>{this.onClick()}}>{this.state.value}</Text>
				)}
			</Box>
		)
	}
	render() {
		return(
			<Box>
				<ResponsiveContext.Consumer>
					{(size) => this.show_input(size)}
				</ResponsiveContext.Consumer>
			</Box>
		);
	}
}

export default UInput;
