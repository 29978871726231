import React from 'react';

import {  Box, Text, Layer, Notification, Menu } from 'grommet';
import { UserAdd, UserExpert, Logout, UserSettings, SettingsOption, UserAdmin } from 'grommet-icons';
import { withRouter } from 'react-router-dom'

import md5 from 'js-md5'

import UInput from '@/component/UInput'
import UButton from '@/component/UButton'

import UStore from '@/UStore'
import USocket from '@/USocket'
import UAPI from '@/UAPI'


const styles = {
	button_border:{
		borderStyle:'solid',
		borderWidth:1,
		borderRadius:20,
	},
	button: {
		opacity:0.98,
		overflow:'visible',
		display:'flex',
		position: 'fixed',
		float:'right',
		width:55,
		height:55,
		bottom: '40%',
		right: 60,
		flexWrap:'wrap',
		zIndex:9999
	},
	logout: {
		overflow:'visible',
		display:'flex',
		position: 'fixed',
		float:'right',
		width:140,
		height:60,
		bottom: 100,
		right: 10,
	},
	login: {
		overflow:'visible',
		display:'flex',
		position: 'fixed',
		float:'right',
		height:'auto',
		bottom: 100,
		right: 10,
	},
}
class Login extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		this.state = {
			loginState: UStore.userinfo.token===""?false:true,
			isshow_toast:false,
			isvisible:false
		}
		this.username=""
		this.password=""
		
		this.xtimer=""
		this.box_ref=""
	}
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
		title:"登录",
		changeState:undefined
	}
	componentWillUnmount=()=>{
		USocket.clean()
	}
	//socket回调
	network_post_callback=(uid, eventid, status, params)=>{
		// console.log(params)
		if(status===200){
			switch(eventid){
				case "userRegst":
					if(params.errno==='1033'){
						this.show_message("注册成功")
					}else{
						this.show_message("注册失败")
					}
				break;
				case "userLogin":
					if(params.errno==='1033'){
						if((params.data!==undefined) && (params.data.token!==undefined)){
							if(UStore.file.saveUser({
								username:this.username,
								token:params.data.token,
								state:params.data.state
							})){
								this.show_message("登录成功")
								this.setState({
									loginState:true
								})
								this.facebackChange(true)
							}else{
								this.show_message("登录失败")
							}
						}
					}else{
						this.show_message("登录失败")
					}
				break;
				default:break;
			}
		}
	}
	/** 网络请求包
	 * @param {Object} eventid 事件ID| POST KEY
	 * @param {Object} data		附加数据
	 */
	request_socket_package=(eventid, data={})=>{
		USocket.request("POST", 1, eventid, this.network_post_callback, UStore.userinfo.console,
			{
				key:eventid,
				username:UStore.userinfo.username,
				value:JSON.stringify(data)
			},
			{'token': UStore.userinfo.token}
		)
	}
	show_message=(message)=>{
		if(this.state.isshow_toast===false){
			if(this.xtimer!==""){
				clearTimeout(this.xtimer)
			}
			this.xtimer=setTimeout(() => {
				clearTimeout(this.xtimer)
				this.xtimer=""
				this.setState({
					isshow_toast:false,
				})
			}, 1000);
		}
		this.setState({
			isshow_toast:true,
			message:message
		})
	}
	check_params=()=>{
		let message=""
		if(this.username.length<5){
			message="请输入正确的用户名"
		}else if(this.password.length<5){
			message="请输入正确的用户密码"
		}
		if(message!==""){
			this.show_message(message)
			return false
		}
		return true
	}
	facebackChange=(islogin)=>{
		UAPI.onChangeState(this.props, islogin)
	}
	changeState=(event)=>{
		// console.log(event)
		this.setState({
			ishow_dialog:false
		})
		switch(event.idx){
			case "user_name":
				this.username=event.value
			break;
			case "user_passwd":
				this.password=event.value
			break;
			case "btn_regin":
				if(this.check_params()){
					this.request_socket_package("userRegst", {
						username:this.username,
						password:this.password
					});
				}
			break;
			case "btn_login":
				if(this.check_params()){
					this.request_socket_package("userLogin", {
						username:this.username,
						password:md5(this.password)
					});
				}
			break;
			case "btn_logout":
				UStore.file.removeUser()
				this.setState({
					loginState:false,
					isvisible:false
				})
				this.facebackChange(false)
			break;
			default:break;
		}
	}
	jump_url=(idx)=>{
		switch(idx){
			case 1://登录
				this.setState({
					isvisible:true
				})
			break;
			case 2://设置
				this.props.history.push('/admin');
			break;
			default:break;
		}
	}
	show_login_box=()=>{
		return(
			<Box align="center" gap="medium">
				<Layer
					style={styles.login}
					animation="slide"
					plain
					modal={false}
					onClickOutside={()=>{this.setState({isvisible:false})}}
					onEsc={()=>{this.setState({isvisible:false})}}>
					<Box
						background={UStore.store.app_item_bagd_color}
						border
						align="center"
						gap="medium"
						round="medium">
						<Text size="medium" weight="bolder">{this.props.title}</Text>
						<Box gap="medium" direction="column">
							<Box fill gap="medium">
								<UInput
									isinput
									align="end"
									label="用户名"
									idx="user_name"
									tips="用户名"
									maxlength="18"
									changeState={this.changeState}/>
							</Box>
							<Box fill gap="medium">
								<UInput
									isinput
									align="end"
									label="密码"
									idx="user_passwd"
									tips="密码"
									maxlength="20"
									changeState={this.changeState}/>
							</Box>
						</Box>
						<Box direction="row" justify="between" gap="medium" pad="medium">
							<UButton
								border
								name="注册"
								idx="btn_regin"
								icon={<UserAdd size="medium"/>} 
								changeState={this.changeState} />
							<UButton
								border
								name="登录"
								idx="btn_login"
								icon={<UserExpert size="medium"/>} 
								changeState={this.changeState} />
						</Box>
					</Box>
				</Layer>
			</Box>
		)
	}
	show_logout_box=()=>{
		return(
			<Box align="center" gap="medium" >
				<Layer
					style={styles.logout}
					animation="slide"
					plain
					modal={false}
					position="right"
					onClickOutside={()=>{this.setState({isvisible:false})}}
					onEsc={()=>{this.setState({isvisible:false})}}>
					<Box
						background={UStore.store.app_item_bagd_color}
						border
						gap="medium"
						align="center"
						round="large">
						<UButton
							border
							name="真是要退出吗!"
							idx="btn_logout"
							icon={<Logout size="medium"/>} 
							changeState={this.changeState} />
					</Box>
				</Layer>
			</Box>
		)
	}
	show_menu=()=>{
		return(<Box justify="center" align="center" pad="large" style={styles.button} >
			<Menu
				plain
				alignSelf="center"
				justifyContent="center"
				icon={<UserSettings size="large"/>}
				dropProps={{
						align: { center: 'bottom', right: 'left' },
						elevation: 'xlarge',
					}}
				items={[
					{
						label: <Box alignSelf="center">设置</Box>,
						onClick: () => {this.jump_url(2)},
						icon: (
							<Box pad="medium"><SettingsOption size="large" /></Box>
						),
					},
					{
						label: <Box alignSelf="center"> {this.state.loginState===false?"登录":"退出"} </Box>,
						onClick: () => {this.jump_url(1)},
						icon: (
							<Box pad="medium">
								{this.state.loginState===false?(
									<UserAdmin size="large" />
								):(
									<Logout size="medium"/>
								)}
							</Box>
						),
					},
				]}
			/>
		</Box>)
	}
	render() {
		return(
			<Box>
				{this.state.isvisible ?(
					this.state.loginState===false?(
						this.show_login_box()
					):(
						this.show_logout_box()
					)
				):(
					<Box style={styles.button}>
						{this.show_menu()}
					</Box>
				)
				}
				{this.state.isshow_toast && <Notification
					status="warning"
					toast
					title="提示"
					message={this.state.message}
					onClose={()=>{this.setState({isshow_toast:false})}}/>
				}
			</Box>
		)
	}
}
export default withRouter(Login);
