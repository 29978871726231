import React from 'react';

import {  Box, Form, MaskedInput, Button, RadioButtonGroup, FormField, Tip, TextInput, Layer, Text, Notification, ResponsiveContext } from 'grommet';
import { withRouter } from 'react-router-dom'

import UStore from '@/UStore'
import USocket from '@/USocket'
import UAPI from '@/UAPI'

import UButton from '@/component/UButton'

class ANewADD extends React.Component {
	constructor(props) {//构造函数
	    super(props);
		this.state = {
			locationList:[
				{key:1,label:"西南", value:1}
				],
			colorList:[
				{key:1,label:"绿色", value:"neutral-1"}
				],
			versions:[
				{key:1, label:"版本1", value:10000}
				],//系统版本
			is_show_dialog:false,
			error_message:"添加失败",
			is_show_edit: true,
			button_label:this.props.id===0?"添加车辆":"更新车辆",
			
			edit:{
				location:"",//区域
				color:"",//颜色
				paid:"",//金额
				version:10000,//版本
				mac:"",//MAC地址
				carno:"",//车辆编号
				tips:""//备注
			}
		}
	}
	//ES6 静态属性默认值——基于static的写法
	static defaultProps = {
		idx:"idx",//主UID
		vid:"vid",//副UID
		id:0//内容ID
	}
	async componentDidMount () {
		USocket.post("carColor", this.network_post_callback)
		USocket.post("carVers", this.network_post_callback)
		USocket.post("carLocN", this.network_post_callback)
	}
	componentWillUnmount=()=>{
		USocket.clean()
		if(this.xtimer)
			clearTimeout(this.xtimer)
	}
	
	// componentWillReceiveProps(nextProps: id) {
	// }
	//socket回调
	network_post_callback=(uid, eventid, status, params)=>{
		// console.log(eventid, status, params)  
		if(status===200){
			switch(eventid){
				case "carColor":
					if(params.errno==="1033" && typeof params.data==="object"){
						let colorList=params.data
						colorList.map((item, index)=>{
							item.value=item.key
							return index
						})
						this.setState({colorList:colorList})
					}
				break;
				case "carVers":
					if(params.errno==="1033" && typeof params.data==="object"){
						let versions=params.data
						versions.map((item, index)=>{
							item.value=item.key
							return index
						})
						this.setState({versions:versions})
					}
				break;
				case "carLocN":
					if(params.errno==="1033" && typeof params.data==="object"){
						let locationList=params.data
						locationList.map((item, index)=>{
							item.value=item.key
							return index
						})
						this.setState({locationList:locationList})
					}
				break;
				case "addCar":
					if(params.errno==="1033"){
						this.showToast("摩宠添加成功") 
						UAPI.onChangeState(this.props, true)
					}else
						this.showToast("摩宠添加失败") 
				break;
				default:break;
			}
		}
	}
	showToast=(message)=>{
		if(message===undefined){
			message="添加失败!"
		}
		this.setState({
			is_show_dialog:true,
			error_message:message
		})
		if(this.xtimer)
			clearTimeout(this.xtimer)
		this.xtimer=setTimeout(() => {
			clearTimeout(this.xtimer)
			this.xtimer=""
			this.setState({
				is_show_dialog:false,
			})
		}, 1000);
	}
	changeState=(event)=>{
		switch(event.idx){
			case "btn_post":
				if(this.props.id===0){
					let data={
						location:9,//区域
						color:1,//颜色
						paid:1,//金额
						version:event.value.version,//版本
						mac:event.value.mac,//MAC地址
						carno:'0044-1-0000-000000',//车辆编号
						motorno:'111-111-1111-1111-11-11-1111',//电机编号
						tips:event.value.tips//备注
					}
					USocket.post("addCar", this.network_post_callback, data)
				}else{
					let editdata={
						id:this.props.id,
						location:this.contextData.location,//区域
						carno:this.contextData.carno,//车辆编号
						tips:this.contextData.tips//备注
					}
					USocket.post("addCar", this.network_post_callback, editdata)
				}
				
			break;
			default:break;
		}
	}
	
	show_hearder=(textsize)=>{
		return(<Box align="start" justif="start" fill="horizontal" overflow="auto" flex>
			<Form 
				align="start" 
				justif="start"
				value={this.hearder}
				validate="blur"
				messages={{ invalid: "输入无效", required: "输入为空" }}
				onSubmit={(event) => this.changeState({
					idx: "btn_post",
					vid: "",
					state: true,
					long: false,
					value: event.value
				})}
				onChange={(value) => this.changeState({
					idx: "form_data",
					vid: "",
					state: true,
					long: false,
					value: value
				})}>
				<FormField label={<Text size={textsize}>系统版本</Text>} name="version" required>
					{this.props.id===0?(
						<Box pad="medium" direction="row" width={textsize=="small"?"medium":"large"} overflow="auto" flex>
							<RadioButtonGroup
								name="version"
								direction="row"
								gap={textsize=="small"?"large":"xsmall"}
								options={this.state.versions}>
								{(option, { checked, focus, hover }) => {
									let border;
									if (checked) border = { color: "status-ok"};
									else border = true;
									return (
										<Box border={border} pad="xsmall">
										<Text size="xsmall">{option.label}</Text>
										</Box>
									);
								}}
							</RadioButtonGroup>
						</Box>
					):(
						<Text w	eight="bold">{Math.floor(this.state.edit.version/10000)}.{Math.floor((this.state.edit.version%10000)/100)}.{Math.floor(this.state.edit.version%100)}</Text>
					) }
				</FormField>
				<FormField label={<Text size={textsize}>说明</Text>} name="tips" pad required
					validate={[
						(tips) => {
							if (tips && tips.length < 3)
								return '说明不少于3个字';
							if(tips==="")
								return '说明不能为空';
							return undefined;
						},
					]}
					formField={{label:{requiredIndicator:"tips"}}}>
					{this.props.id===0?(
						<TextInput name="tips" placeholder='控制终端说明' />
					):(
						<Text weight="bold">{this.state.edit.tips}</Text>
					) }
				</FormField>
				<FormField label={<Text size={textsize}>MAC</Text>} name="mac" pad required
					validate={[
						(mac) => {
							//94:E6:86:1A:5E:EE
							if (mac){
								if(mac.length < 17){
									return '控制终端MAC错误';
								}else if(mac.split(":").length<6){
									return '控制终端MAC错误';
								}
							}
							if(mac==="")
								return '控制终端MAC不能为空';
							return undefined;
						},
					]}
					formField={{label:{requiredIndicator:"mac"}}}>
					{this.props.id===0?(
						<MaskedInput
							mask={[
								{
									length: 2, 
									regexp: /^[0-9,a-f,A-F]{1,2}$/,
									placeholder: 'XX ',
								},
								{ fixed: ':' },
								{
									length: 2,
									regexp: /^[0-9,a-f,A-F]{1,2}$/,
									placeholder: ' XX ',
								},
								{ fixed: ':' },
								{
									length: 2,
									regexp: /^[0-9,a-f,A-F]{1,2}$/,
									placeholder: ' XX ',
								},
								{ fixed: ':' },
								{
									length: 2,
									regexp: /^[0-9,a-f,A-F]{1,2}$/,
									placeholder: ' XX ',
								},
								{ fixed: ':' },
								{
									length: 2,
									regexp: /^[0-9,a-f,A-F]{1,2}$/,
									placeholder: ' XX ',
								},
								{ fixed: ':' },
								{
									length: 2,
									regexp: /^[0-9,a-f,A-F]{1,2}$/,
									placeholder: ' XX ',
								},
							]}
							name="mac"/>
					):(
						<Text weight="bold">{this.state.edit.mac}</Text>
					) }
				</FormField>
				
				<Box direction="row" justify="center" margin={{ top: 'medium'}}>
					<Button type="submit" label={this.state.button_label} primary />
				</Box>
				<Box height="xxsmall" direction="row" justify="end">
				</Box>
			</Form>
		</Box>)
	}
	render() {
		return (
			<Box 
				animation={["fadeIn", "zoomIn"]} 
				background={UStore.store.app_item_bagd_color}
				border
				round="medium"
				align="center"  justif="center"
				overflow="auto" flex>
				<ResponsiveContext.Consumer>
				{(screensize)=>(<Box>
					<Box>
						{this.show_hearder(screensize==="small"?"small":"medium")}
						{ this.state.is_show_dialog && (
						<Layer
							position="top"
							modal={false}
							margin={{ vertical: 'medium', horizontal: 'small' }}
							responsive={false}
							plain>
							<Box pad="large" justify="center">
								<Notification
									status="critical"
									title="提示"
									message={this.state.error_message}
									onClose={()=>{
										this.setState({is_show_dialog:false})
									}}/>
							</Box>
						</Layer>
						)}
					</Box>
				</Box>)}
				</ResponsiveContext.Consumer>
			</Box>
		);
	}
}
export default withRouter(ANewADD);
